.canvas-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    pointer-events: none;
    > * {
        pointer-events: all;
        height: 80px;
        width: 100%;
        cursor: pointer;
    }
}
